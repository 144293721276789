.login_wrap{
  background: url("../img/login/bg.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  .Sign-in{
    width: 353px;
    height: 477px;
    background: #FFF;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -238px 0 0 -176px;
    .Sign-auto{
      span.right{
        cursor: pointer;
      }
    }
    .right-icon-float{
      position: absolute;
      right: 0;
      top: 0;
      border:33px solid #dc2728;
      border-left-color: transparent;
      border-bottom-color: transparent;
      border-top-right-radius: 8px;
      cursor: pointer;
      //&.active,&:hover{
      //  .icon1{
      //    top: -66px;
      //    right: 66px;
      //  }
      //  .icon2{
      //    top: 0;
      //    right: 6px;
      //  }
      //}
      &.active{
        .icon1{
          top: -66px;
          right: 66px;
        }
        .icon2{
          top: 0;
          right: 6px;
        }
      }
      .iconfont{
        width: 66px;
        height: 66px;
        position: absolute;
        right: 6px;
        top: 0px;
        text-align: right;
        color: #FFF;
        font-size: 36px;
      }
      .icon2{
        top: 66px;
        right: -66px;
      }
      .icon1,.icon2{
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
      }
      .iconList{
        width: 66px;
        height: 66px;
        position: absolute;
        left: -33px;
        top: -33px;
        overflow: hidden;
      }
      .bg2{
        display: inline-block;
        position: absolute;
        left: -33px;
        top: -33px;
        width: 66px;
        height: 66px;
        background: url("../img/login/bg2.png") no-repeat;
        overflow: hidden;
        z-index: 2;
      }
    }
    .Sign-in-content{
      padding: 20px;
    }
    h3{
      font-size: 12px;
      text-align: right;
      color: #dc2728;
      font-weight: normal;
      padding-right: 42px;
    }
    label{
      color: #666;
    }
    .inputBg{
      background: #F6F6F6;
      width: 304px;
      height: 30px;
      font-size: 14px;
      color: #333;
      display: inline-block;
      border:none;
      border-radius: 4px;
      margin-bottom: 8px;
      text-indent: 7px;
    }
    .inputSmall{
      width: 218px;
    }
    .Sign-btn {
      a {
        width: 304px;
        height: 30px;
        border-radius: 4px;
        display: block;
        color: #fff;
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        margin-top: 8px;

      }
      .btnRed {
        background: #DC2728;
      }
      .btnOragin {
        background: #F5A623;
      }
    }
    ul {
      li {
        span {
          font-size: 12px;
          color: #666;
        }
      }
      .Sign-auto {
        margin: 13px 0;
        input {
          float: left;
          margin: 2px 4px 0 0;
        }
      }
    }
    .register{
      //position: absolute;
      //z-index: 10;
      //width: 304px;
      //height: 235px;
      //border-radius: 4px;
      //padding: 20px;
      //z-index: 10;
      //background: #fff;
      //left: 50%;
      //top: 50%;
      //margin-left: -172px;
      margin-top:67px;
      .gitCode{
        width: 80px;
        height: 30px;
        border-radius: 4px;
        background: #D7D7D7;
        color: #fff;
        font-size: 14px;
        line-height: 30px;
        display: inline-block;
        *display:inline;
        *zoom:1;
        margin-left: 6px;
        border: none;
        &.active{
          background: #dc2728;
          color: #FFF;
        }
      }
    }
    //二维码
    .qrcode_img{
      width: 100%;
      text-align: center;
      margin-top: 22px;
      img{
        width: 200px;
        height: 200px;
        text-align: center;
      }
      .txt{
        font-size: 18px;
        line-height: 25px;
        margin: 14px 0 40px 0;
      }
    }
    .qrcode_tip{
      padding: 0 35px;
      dl{
        font-size: 14px;
        dd,dt{
          display: inline-block;
        }
        dt{
          font-size: 30px;
          line-height: 134%;
          color: #dc2728;
          vertical-align: top;
        }
      }
    }
  }
}

//登录失效
.login_wrap{
  .lost{
    > * {
      .blur(5px)  ;
    }
    .qrcode_lost{
      display: table;
    }

  }
  .qrcode_lost{
    display: none;
    .blur(0px);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
    z-index: 10;
    background: rgba(0,0,0,0.3);
    border-radius: 8px;
    .content{
      width: 100%;
      height: 100%;
      font-size: 18px;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      color: #FFF;
    }
    .iconfont{
      font-size: 60px;
    }
  }
}