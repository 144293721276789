
.btns{
    width: 100%;
    font-size: 0;
    vertical-align: top;
    text-align: center;
}
.btns-red,.btns-yellow{
    width: 100px;
    height: 24px;
    line-height: 24px;
    background: #DC2728;
    color: #FFF;
    font-size: 12px;
    border-radius: 3px;
    text-align: center;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    &.disabled{
        background: #D7D7D7;
    }
}
.btns-small{
    width: 160px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
}

.btns-yellow{
    background: #F8E71C;
    color: #666;
}
.btns-orange-big{
    width: 100%;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    background: #F5A623;
    border-radius: 4px;
    text-align: center;
    color: #FFF;
    display: inline-block;
    *display: inline;
    *zoom: 1;
}



.btns_red2{
    width: 110px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 8px;
    font-size:14px;
    color: #FFF;
    background: #dc2728;
    display: inline-block;
    .iconfont{
        margin-right: 5px;
        position: relative;
        top:1px;
    }
}
.btn_b_red{
    width: 120px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    text-align: center;
    color: #FFF;
    border:1px solid #dc2728;
    display: inline-block;
    position: relative;
    overflow: hidden;
    .c_red;
    .iconfont{
        margin-right: 5px;
        position: relative;
        top:1px;
    }
    .file{
        .opacity(0);
        font-size: 100px;
        position: absolute;
        left: 0;
        top:0;
        z-index: 2;
    }
}
.btn_b_red_l,.btn_red_l,.btn_gray_l,.btn_b_gray_l,.btn_b_note_l{
    width: 240px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    text-align: center;
    border:1px solid #dc2728;
    display: inline-block;
    &.disabled{
        border-color: #D7D7D7;
        color: #FFF !important;
        background: #D7D7D7;
    }
}
.btn_red_l{
    background: #dc2728;
    color: #FFF;
}
.btn_gray_l{
    background: #d1d1d1;
    border-color: #d1d1d1;
    color: #FFF;
}
.btn_b_gray_l{
    border-color: #666666;
    .c_gray;
}
.btn_b_note_l{
    border-color: #E1E1E1;
    .c_note;
}
.btn_b_red_l{
    .c_red;
}