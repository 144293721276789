.suspend-nav{
    width: 68px;
    position: fixed;
    right: 11px;
    top: 215px;
    z-index: 9;
    text-align: center;
    //-webkit-transition: 0.2s all;
    //-moz-transition: 0.2s all;
    //-ms-transition: 0.2s all;
    //-o-transition: 0.2s all;
    //transition: 0.2s all;
    a{
        width: 68px;
        height: 68px;
        border-radius: 50%;
        position: relative;
        display: block;
        color: #fff;
        .mask{
            filter:alpha(opacity=70); 
            -moz-opacity:0.7; 
            opacity:0.7;
            width: 68px;
            height: 68px;
            border-radius: 50%;
            display: block;
        }
        .txt{
            position: absolute;
            z-index: 1;
            width: 100%;
            left: 0;
            
            i{
                font-size: 26px;
                display: block;
                margin-top: 11px;
            }
            strong{
                font-size: 12px;
                color: #fff;
                line-height: 17px;
            }
            
        }
    }
    .dot{
        width: 4px;
        height: 4px;
        border-radius: 50%;
        display: inline-block;
        margin: 1px 0 2px 0;
    }
    .red{
        background: #DC2728;
    }
    .orange{
        background: #F5A623;
    }
    .s-customer .mask{
       background: #DC2728; 
    }
    .wx-order .mask{
        background: #F5A623; 
    }
    .code{
        width: 114px;
        height: 104px;
        background: url("../img/icon.png") -126px -488px no-repeat;
        position: absolute;
        top: 170px;
        left: -116px;
        display: none;
    }
}
