input[type='radio'],
input[type='checkbox'] {
  display: none;
  cursor: pointer;
}
input[type='radio']:focus, input[type='radio']:active,
input[type='checkbox']:focus,
input[type='checkbox']:active {
  outline: none;
}
input[type='radio'] + label,
input[type='checkbox'] + label {
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-right: 10px;
  color: #0b4c6a;
}
input[type='radio'] + label:before, input[type='radio'] + label:after,
input[type='checkbox'] + label:before,
input[type='checkbox'] + label:after {
  content: '';
  font-family: helvetica;
  display: inline-block;
  width: 18px;
  height: 18px;
  left: 0;
  bottom: 0;
  text-align: center;
  position: absolute;
}
input[type='radio'] + label{
  position: relative;
  top:4px;
}
input[type='radio'] + label:before,
input[type='radio'] + label:after {
  width: 14px;
  height: 14px;
}
input[type='radio'] + label:before {
  top:3px;
}

input[type='checkbox'] + label:before,
input[type='checkbox'] + label:after {
  font-family: iconfont;
}
input[type='radio'] + label:before,
input[type='checkbox'] + label:before {
  background-color: #FFF;
  border:1px solid #E1E1E1;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
}
input[type='radio'] + label:after,
input[type='checkbox'] + label:after {
  color: #fff;
}
input[type='radio']:checked + label:before,
input[type='checkbox']:checked + label:before {
  -moz-box-shadow: inset 0 0 0 10px #dc2728;
  -webkit-box-shadow: inset 0 0 0 10px #dc2728;
  box-shadow: inset 0 0 0 10px #dc2728;
  border:none;

}

/*Radio Specific styles*/
input[type='radio'] + label:before {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
input[type='radio'] + label:hover:after, input[type='radio']:checked + label:after {
  content: '\2022';
  position: absolute;
  top: 3px;
  //left: -1px;
  font-size: 20px;
  line-height: 14px;
}
input[type='radio'] + label:hover:after {
  color: #c7c7c7;
}
input[type='radio']:checked + label:after, input[type='radio']:checked + label:hover:after {
  color: #fff;
}

/*Checkbox Specific styles*/
input[type='checkbox'] + label:before {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
input[type='checkbox'] + label:hover:after, input[type='checkbox']:checked + label:after {
  content: "\e618";
  line-height: 20px;
  font-size: 14px;
}
input[type='checkbox'] + label:hover:after {
  color: #c7c7c7;
}
input[type='checkbox']:checked + label:after, input[type='checkbox']:checked + label:hover:after {
  color: #fff;
}

/*Toggle Specific styles*/
input[type='checkbox'].toggle {
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 55px;
  height: 28px;
  background-color: #fafafa;
  position: relative;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
input[type='checkbox'].toggle:hover:after {
  background-color: #c7c7c7;
}
input[type='checkbox'].toggle:after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #adadad;
  top: 2px;
  left: 2px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
input[type='checkbox']:checked.toggle {
  -moz-box-shadow: inset 0 0 0 15px #dc2728;
  -webkit-box-shadow: inset 0 0 0 15px #dc2728;
  box-shadow: inset 0 0 0 15px #dc2728;
}
input[type='checkbox']:checked.toggle:after {
  left: 29px;
  background-color: #fff;
}


/*Toggle Specific styles*/
input[type='checkbox'].toggle2,.ms-drop input[type='checkbox'] {
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: #FFF;
  border:1px solid #D7D7D7;
  position: relative;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
}

input[type='checkbox']:checked.toggle2,.ms-drop input[type='checkbox']:checked {
  background: #dc2728;
  border:1px solid #dc2728;
}
input[type='checkbox']:checked.toggle2:after,.ms-drop input[type='checkbox']:checked:after {
  font-family: iconfont;
  content: '\e618';
  color: #FFF;
  position: absolute;
}


input[type=radio]+label:before{
  background: url("../img/icon/radio.png") no-repeat;
  border:none;
}
input[type=radio]+label:hover:after, input[type=radio]:checked+label:after{
  background: url("../img/icon/radio.png") 0px -46px no-repeat;
  border:none;
  content: "";
}
input[type=radio]:checked+label:before{
  box-shadow: none;
  display: none;
}
input[type=radio]+label:hover:before{
  display: none;
} 