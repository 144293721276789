@import "mixin.less";
.container{
    width: 1008px;
    margin: 0 auto;
    position: relative;
    z-index:1;
    font-size: 0;
    vertical-align: top;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    .span6{
        display: inline-block;
        width: 50%;
        font-size:14px;
    }
}

/** 1000 **/
@media only screen and (min-width: 1000px) and (max-width: 1200px) {

}
/** 1200-1400 **/
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .container{
        width: 1200px;
    }
    .send-activity dd{
        width: 1008px;
    }

}
/** 1400+ **/
@media only screen and (min-width: 1400px)  {
    .container{
        width:1400px ;
    }
    .send-activity dd{
        width: 1192px !important;
    }
}

/**
*全局响应式配置
*/
//1000
@media only screen and (min-width: 1000px) and (max-width: 1200px) {

}
//1200-1400
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    //个人中心-发布活动-活动列表
    .activity-list{
        .name{
            width: 700px !important;
        }
    }

}
//1400+
@media only screen and (min-width: 1400px)  {
    //个人中心-发布活动-活动列表
    .activity-list{
        .name{
            width: 900px !important;
        }
    }
}



//用户中心
#userInfo{
    width: 100%;
    height: 109px;
    position: relative;
    display: inline-block;
    font-size: 0;
    vertical-align: top;
    background: #FFF;
    border-bottom: 1px solid #D7D7D7;
    .span2,.span10{
        display: inline-block;
        vertical-align: top;
    }
    .span2{
        .userPic{
            margin-top: 15px;
            width: 71px;
            height: 71px;
            border-radius: 50%;
        }
    }
    .span10{
        font-size: 14px;
        padding-left: 30px;
        strong{
            //font-size: 16px;
            margin-right: 14px;
            font-weight: normal;
        }
        .desc{
            margin-top: 14px;
        }
        .subNav{
            padding-top: 25px;
            font-size: 16px;
            a{
                color: inherit;
                padding-right: 40px;
                .iconfont{
                    color: #d7d7d7;
                    margin-right: 6px;
                    font-size: 22px;
                    position: relative;
                    top: 2px;
                }
                &.c_red{
                    .iconfont{
                        .c_red;
                    }
                }
            }
        }
    }
    .bg{
        width: 100%;
        height: 60px;
        background: #F2F2F2;
        position: absolute;
        left:0;
        bottom:0;
    }
}
//没有活动数据
.noActivity{
    font-size: 14px;
    text-align: center;
    height: 800px;
    padding-top: 100px;
    .c_note;
    p{
        padding: 4px 0;
    }
}
//canvas圆圈
.jqm-round-wrap{
    display:block;position:relative;width:100px;height:100px;overflow:hidden;
    border-radius:65px;
    -webkit-border-radius:65px;
    text-align: left;
}
.jqm-round-sector{
    position:absolute;width:100px;height:100px;
}
.jqm-round-bg {
    position:absolute;width:100px;height:100px;background-color:#f76220;
    border-radius:65px;
    -webkit-border-radius:65px;
    display: none;
}
.jqm-round-circle{
    position:absolute;background-color:#FFF;width:96px;height:96px;left:2px;top:2px;z-index:10;
    border-radius:60px;
    -webkit-border-radius:60px;
    display: table;
}
.jqm-round-circle p{font-size:14px; height:96px;line-height:150%;margin:0;text-align:center;width:100%; display: table-cell;vertical-align: middle;}
.jqm-round-circle p span.title{
    font-size: 28px;
    color: #666666;
}
.jqm-round-circle p span.c_note{
    font-weight: normal;
    color: #828282;
}
.jqm-round-txt{
    font-size: 14px;
    color: #828282;
}