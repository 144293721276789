//mixin中的函数类似于传统的class
//不同的是传统class直接写在dom节点上，如清浮动的：<div class="clearfix">
//这里的函数，是通过class去调用的，例如清浮动时：
//.div {
//  .clearfix();
//}

//清除浮动
.basefix() {
  &:before, 
  &:after { 
    content: " "; 
    display: table; 
  }
  &:after {
    clear: both;
  }
}
//show hide
.show{ display: block !important; _display:block;}
.hide{ display: none !important; _display:none;}

//相邻inline-block div 3像素差
.inline-block-fix() {
  font-size: 0;
  // *word-spacing: -1px;
}

.inline-block() {
  display:inline-block; 
  // *display:inline; 
  // *zoom:1;
  letter-spacing: normal;
  word-spacing: normal;
}

//背景图片
.background_image(@url; @repeat;@left:0;@top:0){
  background:url(@url) @repeat @left @top;
}

.background_size(@width; @height){
  background-size:@width @height;
  -webkit-background-size:@width @height;
}  

//通用背景图片（按照top，left,no-repeat）
.background_common_image(@url;@width;@height){
  .background_image(@url;no-repeat;0,0);
  .background_size(@width;@height);
}

//flex box
.flex_box_container() {
  display: box;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex_box() {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  display: box;
  display: -webkit-box;
  display: -moz-box;
}
 
.box_flex(@num){
  -webkit-box-flex: @num;
  -moz-box-flex: @num;
  -webkit-flex: @num;
  -ms-flex: @num;
  flex: @num;
}

//默认背景图片
.default_bg(@size) {
  background: #fff url("@{pic_url_common}default130.png") no-repeat 50% 50%;
  background-size: @size @size;
  -webkit-background-size: @size @size;
}

//大小尺寸
.size(@width; @height) {
  width: @width;
  height: @height;
}

//box_sizing
.box_sizing() {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.box_align(@val:start){
  box-align: @val;
  -mozbox-align: @val;
  -o-align: @val;
  -webkit-box-align: @val;
}
.box_pack(@val:start){
  box-pack: @val;
  -moz-box-pack: @val;
  -o-box-pack: @val;
  -webkit-box-pack: @val;
}

//文字超出显示...
.ellipsis() {
  text-overflow: ellipsis;
    white-space: nowrap;
       overflow: hidden;
}

//多行文字超出显示...
.multi_ellipsis(@line) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: @line;
  overflow:hidden;
}

//隐藏文字
.hide_text() {
  font: 0/0 arial;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

//border小三角
.border_triangle(@width; @color1; @color2; @top; @right; @bottom; @left) {
  width: @width;
  border-color: @color1 @color2;
  border-style: solid;
  border-width: @top @right @bottom @left;
}

//border箭头
.border_arrows(@wafter; @wbefore; @tafter; @tbefore){
	display:inline-block;
	width: 0;
	height: 0;
	position: relative;
	&:after,&:before{
		border: solid transparent;
		content: ' ';
		height: 0;
		left: 100%;
		position: absolute;
		width: 0;
	}
	&:after{
		border-width: @wafter;
		border-left-color: #c8c8c8;
		top: @tafter;
	}
	&:before{
		border-width: @wbefore;
		border-left-color: #fff;
		top: @tbefore;
	}
}

//颜色
.font(@color) {
  color: @color;
}

//:before :after图标
.before_icon(@width; @height){
  content: "";
  display: block;
  overflow: hidden;
  position:relative;
  .size(@width; @height);
}

.after_icon(@width; @height){
  content: "";
  display: block;
  overflow: hidden;
  position:relative;
  .size(@width; @height);
}

//半像素border 图片实现
.border_half() {
    border-width: 0px;
    border: 1px solid #c8c8c8;
    -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
}

.border_top_half() {
    border-width: 0;
    border-top: 1px solid #c8c8c8;
    -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
  }

.border_bottom_half() {
    border-width: 0;
    border-bottom: 1px solid #c8c8c8;
    -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
}

.border_left_half() {
    border-width: 0;
    border-left: 1px solid #c8c8c8;
    -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
}

.border_right_half() {
    border-width: 0;
    border-right: 1px solid #c8c8c8;
    -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
}

.border_vertical_half() {
    border-width: 0;
    border-top: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
    -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
}

.border_horizontal_half() {
    border-width: 0;
    border-left: 1px solid #c8c8c8;
    border-right: 1px solid #c8c8c8;
    -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
}

.border_left_bottom_half() {
    border-width: 0;
    border-left: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
    -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
}

.border_right_bottom_half() {
    border-width: 0;
    border-right: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
    -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
}

.border_left_top_half() {
    border-width: 0;
    border-top: 1px solid #c8c8c8;
    border-left: 1px solid #c8c8c8;
    -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
}

.border_right_top_half() {
    border-width: 0;
    border-top: 1px solid #c8c8c8;
    border-right: 1px solid #c8c8c8;
    -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
}

//半像素border 背景渐变实现
.border_bottom(@color) {
  border: none;
  // background-image: -moz-linear-gradient(top, transparent, transparent 50%, @color 50%); // FF 3.6+
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(@color)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, transparent, transparent 50%, @color 50%); // Safari 5.1+, Chrome 10+
  // background-image: -o-linear-gradient(top, transparent, transparent 50%, @color 50%); // Opera 11.10
  background-image: linear-gradient(to bottom, transparent, transparent 50%, @color 50%); // Standard, IE10
  background-size: 100% 1px;
  background-repeat: no-repeat;
  background-position: bottom;
}

.border_top(@color) {
  border: none;
  // background-image: -moz-linear-gradient(top, @color, @color 50%, transparent 50%); // FF 3.6+
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(@color)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, @color, @color 50%, transparent 50%); // Safari 5.1+, Chrome 10+
  // background-image: -o-linear-gradient(top, @color, @color 50%, transparent 50%); // Opera 11.10
  background-image: linear-gradient(to bottom, @color, @color 50%, transparent 50%); // Standard, IE10
  background-size: 100% 1px;
  background-repeat: no-repeat;
  background-position: top;
}

//圆角
.border-radius(@radius) {
  border-radius: @radius;
  -webkit-border-radius: @radius;
}

.border_top_radius(@radius) {
  border-top-right-radius: @radius;
  border-top-left-radius: @radius;
  -webkit-border-top-right-radius: @radius;
  -webkit-border-top-left-radius: @radius;
}

.border_right_radius(@radius) {
  border-bottom-right-radius: @radius;
  border-top-right-radius: @radius;
  -webkit-border-top-right-radius: @radius;
  -webkit-border-top-left-radius: @radius;
}

.border_bottom_radius(@radius) {
  border-bottom-right-radius: @radius;
  border-bottom-left-radius: @radius;
  -webkit-border-top-right-radius: @radius;
  -webkit-border-top-left-radius: @radius;
}

.border_left_radius(@radius) {
  -webkit-border-bottom-left-radius: @radius;
  -webkit-border-top-left-radius: @radius;
  border-bottom-left-radius: @radius;
  border-top-left-radius: @radius;
}

.border-top-left-radius(@radius) {
  -webkit-border-top-left-radius: @radius;
      // -moz-border-radius-topleft: @radius;
          border-top-left-radius: @radius;
}
.border-top-right-radius(@radius) {
  -webkit-border-top-right-radius: @radius;
      // -moz-border-radius-topright: @radius;
          border-top-right-radius: @radius;
}
.border-bottom-right-radius(@radius) {
  -webkit-border-bottom-right-radius: @radius;
      // -moz-border-radius-bottomright: @radius;
          border-bottom-right-radius: @radius;
}
.border-bottom-left-radius(@radius) {
  -webkit-border-bottom-left-radius: @radius;
      // -moz-border-radius-bottomleft: @radius;
          border-bottom-left-radius: @radius;
}

//box阴影
.box_shadow(@x; @y; @r; @color) {
  -webkit-box-shadow: @x, @y, @r, @color;
  box-shadow: @x @y @r @color;
}

//box阴影
.text_shadow(@x; @y; @r; @color){
  text-shadow:@x @y @r @color;
}

//animation
.animation(@animation) {
  -webkit-animation: @animation;
  animation: @animation;
}

//Transformations/2D
.rotate(@degrees) {
  -webkit-transform: rotate(@degrees);
  transform: rotate(@degrees);
}

.scale(@ratioX; @ratioY) {
  -webkit-transform: scale(@ratioX, @ratioY);
  transform: scale(@ratioX, @ratioY);
}

.translate(@x; @y) {
  -webkit-transform: translate(@x, @y);
  transform: translate(@x, @y);
}

.skew(@x; @y) {
  -webkit-transform: skew(@x, @y);
  transform: skew(@x, @y);
}

//Transformations/3D
.translate3d(@x; @y; @z) {
  -webkit-transform: translate3d(@x, @y, @z);
  transform: translate3d(@x, @y, @z);
}

//元素基点
.transform_origin(@originX; @originY) {
  -webkit-transform-origin: @originX,@originY;
  transform-origin: @originX, @originY;
}

//Transitions
.transition(@property; @duration; @function; @delay) {
  -webkit-transition: @property, @duration, @function, @delay;
  transition: @property, @duration, @function, @delay;
}

//线性渐变
.linear_gradient(@direction; @color1; @color2){
  background-image:-webkit-linear-gradient(to @direction, @color1, @color2);
  background-image:linear-gradient(to @direction, @color1, @color2);
}

//Opacity
.opacity(@opacity) {
  opacity: @opacity / 100;
  -webkit-opacity: @opacity / 100;
  filter: ~"alpha(opacity=@{opacity})";
  -wekkit-filter: ~"alpha(opacity=@{opacity})";
}

//图片自适应
.maxwidth(){
  max-width: 100%;
  height: auto;
}

//webkit-box弹性盒子模型
.webkit-box(@orient:horizontal;@align:start;@pack:start;){
    display:-webkit-box; 
    -webkit-box-orient:@orient;
    -webkit-box-align:@align;
    -webkit-box-pack:@pack;
    box-sizing:border-box;
}
//清除所有浮动
.clear{clear: both;}
// 图片url默认路径
// -------------------------
@pic_url_common: "../images/";
@pic_url: "../images/";
.ajax_loading{
    width: 100%; 
    height: 100%; 
    position: fixed; 
    left: 0;
    top: 0; 
    z-index: 100000;  
    overflow: hidden;
    background: rgba(0,0,0,0.5);
    &::before{ 
        display: block;
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -30px 0 0 -30px;
        background: url("@{pic_url}loading.gif") no-repeat;
        background-size:42px 42px ;
        width: 100%;
        height:60px;
    }
    &.absolute{
        position: absolute;
        background: #FFF;
    }
}
.bgwhite{background: #FFF;}   
.btn_orange{
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #FFF;
    background: @orange;
    font-size: 1.3rem;
    font-family: inherit;
    display: block;
}
.ajaxSatus{
    width: 100%;
    line-height: 1.5;
    font-size: 0.09rem;
    text-align: center;
    color: #888;
}
// 公用字体
@font10:0.10rem;
@font11:0.11rem;
@font12:0.12rem;
@font13:0.13rem;
@font14:0.14rem;
@font15:0.15rem;
@font16:0.16rem;
@font17:0.17rem;
@font18:0.18rem;
@font19:0.19rem;
@font20:0.20rem;
@font21:0.21rem;
@font22:2.2rem;
@font23:2.3rem;
@font24:2.4rem;
@font25:2.5rem;
@font26:2.6rem;
@font27:2.7rem;
@font28:2.8rem;
@font29:2.9rem;
@font30:3.0rem;
@font36:3.6rem;
@font48:4.8rem;
@font50:5.0rem;
@font58:5.8rem;
//公用color 
@c_fff:#fff;
@c_000:#000;
@c_333:#333;
@c_444:#444;
@c_666:#666;
@c_999:#999;
@c_a8b2c2:#a8b2c2;
@c_e0edfc:#e0edfc;
@c_e1e1e1:#e1e1e1;
@c_b5b5b5:#b5b5b5;
@c_a7b2c1:#a7b2c1;
@c_4A586A:#4A586A;
@c_4a586a:#4a586a;
@c_c8c8c8:#c8c8c8;
@c_d1d5de:#d1d5de;
@blue:#59a5f0;
@red:#eb3541;
@orange:#FF7F00;
@c_d6d6d6:#d6d6d6;
@gray:#96A2B5;
@green:#79CD31; 
.orange{ color: @orange;}
.blue{ color: @blue;}
