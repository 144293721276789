*{
    margin: 0;
    padding: 0;
    line-height: 150%;
    font-family:tahoma,"Microsoft Yahei",arial,"Hiragino Sans GB","Microsoft Yahei","\5b8b\4f53";
}
*{
  box-sizing: border-box;
}
body{ margin: 0; padding: 0; font-size: 12px; color: #3B3A40; visibility: visible;background: #f6f6f6;}
html{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a{ text-decoration: none; outline: none}
a:focus{
  outline: none;
}
a::-moz-focus-inner{outline:none;}
ul,p,h1,h2,h3,h4,h5,dl,dt,dd,li,i{ margin: 0; padding: 0; list-style: none;}
*:focus,input{ outline: none; }
table{
    border-collapse: collapse; 
}
input:-moz-placeholder,
textarea:-moz-placeholder {
    color: #D7D7D7;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #D7D7D7;
}
input:-webkit-input-placeholder,
textarea:-webkit-input-placeholder {
    color: #D7D7D7;
}

@font-face {font-family: "iconfont";
  src: url('../font/iconfont.eot'); /* IE9*/
  src: url('../font/iconfont.eot') format('embedded-opentype'), /* IE6-IE8 */
  url('../font/iconfont.woff') format('woff'), /* chrome, firefox */
  url('../font/iconfont.ttf') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('../font/iconfont.svg') format('svg'); /* iOS 4.1- */
}

.iconfont {
  font-family:"iconfont" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

