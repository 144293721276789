/*分页*/
.pagination2{
    width: 100%;
    text-align: center;
    font-size: 0;
     span,a,buttom{
        padding: 0 10px;
        text-align: center;
        line-height: 28px;
        display: inline-block;
        font-size: 12px;
        cursor: pointer;
        border-radius: 8px;
        margin: 0 3px;
    }
    a{
        color: #3B3A40;
    }
    span,a,ul,div{
        overflow: hidden;
    }
    ul,div{
        display: inline-block;
        *display: inline;
        *zoom: 1;
    }
    .pageList{
        .wauto{
            background: #FFCF70;
            color:#A83D24;
            border: solid 1px #F5A623;
        }
    }
    ul{
        border: solid 1px #E1E1E1;
        height: 28px;
        text-align: center;
        border-radius:8px;
        line-height: 28px;
        display: inline-block;
        margin: 0 3px;
        li{
            display: inline-block;
            display: inline;
            zoom: 1;

            a{
                padding: 0 10px;
                text-align: center;
                line-height: 28px;
                border-radius:0;
                margin: 0;
                &:hover{
                   background: #F5A623;
                   color: #fff;
                }
            }
            .active{
                background: #F5A623;
                color: #fff;
            }
        }
    }
    .pageJump{
        border: solid 1px #E1E1E1;
        display: inline-block;
        border-radius: 8px;
        font-size: 14px;
        margin: 0 3px;
        label{

            color: #3B3A40;
            padding: 0 10px;
            border-right: solid 1px #E1E1E1;
            line-height: 28px;
            display: inline-block;
        }
        input{
            width: 45px;
            height: 28px;
            border: none;
            padding: 0 5px;
        }
    }
    .jumpGo{
        display: inline-block;
        font-size: 14px;
        vertical-align: top;
        margin: 0 3px;
        padding: 0 4px;
    }

//  span,a{
////      width: 20px;
////      height: 20px;
//      padding: 0 10px;
//      text-align: center;
//      line-height: 28px;
//      display: inline-block;
//      font-size: 12px;
//      cursor: pointer;
//      margin: 0 3px;
//      border-radius: 8px;
//  }
//  a{
//      background: #F6F6F6;
//      color: #828282;
//  }
//  a.active{
//      background: #F5A623;
//      color: #fff;
//  }
//  a:hover{
//     background: #F5A623;
//     color: #fff;
//  }
//  span{
//      background: #FFCF70;
//      color:#A83D24;
//      border: solid 1px #F5A623;
//  }
}
.pagination {
    width: 100%;
    height: 40px;
    margin: 0 auto;
    //padding: 12px 0;
    text-align: center;
    overflow: hidden;
}
.pagination ul li {
    list-style: none;
    display: inline;
    padding: 5px 10px;
    margin: 0 3px;
    color: #666;
}
.pagination ul li a {
    color: #666;
    text-decoration: none;
}
.pagination ul li.home,.pagination ul li.first,.pagination ul li.next,.pagination ul li.last,.pagination input[type="button"]{
    background: #FFCF70;
    color:#A83D24;
    border: solid 1px #F5A623;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    a{
        color: #A83D24;
    }
}
.pagination ul div.item{
    display: inline-block;
    *display:inline;
    *zoom: 1;
    border: solid 1px #E1E1E1;
    height: 28px;
    line-height: 28px;
    margin-left: 5px;
    margin-right:3px;
    vertical-align: top;
    //padding-right: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}
.pagination ul div.item.onlyOne{
    border-color: #F5A623;
}
.pagination ul div.item li{
    margin: 0;
    display: inline-block;
    vertical-align: top;
}
.pagination ul div.item li:nth-child(1){
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.pagination ul div.item li:nth-last-child(1){
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.pagination ul div.item .spanDot{
    padding:0 5px;
    line-height: 100%;
    position: relative;
    top: -3px;
}
.pagination ul li.home.disabled,.pagination ul li.first.disabled,.pagination ul li.next.disabled,.pagination ul li.last.disabled{
    background: #FFEAC0;
    color:#E3CECA;
    border: solid 1px #FFD083;
    border-radius: 8px;
    a{
        color: #A83D24;
    }
}

.pagination ul li.active {
    background: #F5A623;
    position: relative;
    top:0px;
    color: #FFF;
}
.pagination ul li.active a {
    color: #FFF;
}
.pagination ul li.disabled {
    color: #CCC;
}
.pagination ul li.disabled a {
    color: #CCC;
}
.pagination ul li.arrow {
    font-size: 30px;
    line-height: 100%;
    position: relative;
    top: 3px;
}
.pagination ul li .input {
    width: 26px;
    height: 12px;
    padding: 8px 5px;
    border: 1px solid #a1a1a1;
    box-sizing: content-box;
}
#pagination_g_btn_go_input{
    margin: 0 3px;
    border:solid 1px #E1E1E1;
}
.pagination input[type="button"]{
    padding:  5px 11px;
    margin-left: 5px;
    cursor: pointer;
    display: inline-block !important;
}
