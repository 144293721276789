#header{
    width:100%;
    height: 46px;
    line-height: 46px;
    background: #DC2728;
    display: block;
    .logo{
        //position: relative;
        //top: 10px;
    }
    .span6{
        height: 46px;
        line-height: 46px;
        //vertical-align: middle;
        img{
            vertical-align: middle;
        }
    }
    .span6.tr{
        a{
            margin-left: 14px;
            .iconfont{
                position: relative;
                top:1px;
                margin-right: 2px;
            }
        }
    }
    a{
        color: #FFF;
    }
    .container{
        z-index: 2;
    }
}

.to_down_tip{
    position: absolute;
    top:20px;
    right: 140px;
    display: none;
}


