@c_fff:#fff;
@c_DC2728:#DC2728;
@rem:200rem;
.H60{ line-height: 60px;}
.left{ float: left;}
.right{float: right;}
.center{text-align: center !important;}
.txt-right{ text-align: right !important;}
.clear{ clear: both;display: block; width: 0; height: 0; overflow: hidden; line-height: 0; font: 0;}
.bF6{ background: #F6F6F6 !important;}
.bff{ background: #fff !important;} 
.hide{
    display: none !important;
}
.show{
    display: block !important;
}

.flex_box() {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    display: box;
    display: -webkit-box;
    display: -moz-box;
}
.box_align(@val:start){
    box-align: @val;
    -mozbox-align: @val;
    -o-align: @val;
    -webkit-box-align: @val;
}
.box_pack(@val:start){
    box-pack: @val;
    -moz-box-pack: @val;
    -o-box-pack: @val;
    -webkit-box-pack: @val;
}
.box_flex(@num){
    -webkit-box-flex: @num;
    -moz-box-flex: @num;
    -webkit-flex: @num;
    -ms-flex: @num;
    flex: @num;
}

.flex_box_container() {
    display: box;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.size(@width; @height) {
    width: @width;
    height: @height;
}
//
////mixin中的函数类似于传统的class
////不同的是传统class直接写在dom节点上，如清浮动的：<div class="clearfix">
////这里的函数，是通过class去调用的，例如清浮动时：
////.div {
////  .clearfix();
////}
//
////清除浮动
//.basefix() {
//&:before,
//&:after {
//  content: " ";
//  display: table;
//}
//&:after {
//  clear: both;
//}
//}
//.clear{
//  clear: both;
//  line-height: 0;
//  height: 0;
//  overflow: hidden;
//}
//
////show hide
//.show{ display: block !important; _display:block;}
//.hide{ display: none !important; _display:none;}
//
////相邻inline-block div 3像素差
.inline-block-fix() {
font-size: 0;
// *word-spacing: -1px;
}

.inline-block() {
display:inline-block;
// *display:inline;
// *zoom:1;
letter-spacing: normal;
word-spacing: normal;
}
//模糊
.blur(@blur){
    -webkit-filter: blur(@blur); /* Chrome, Opera */
    -moz-filter: blur(@blur);
    -ms-filter: blur(@blur);
    filter: blur(@blur);
}
////背景图片
//.background_image(@url; @repeat;@left:0;@top:0){
//background:url(@url) @repeat @left @top;
//}
//
//.background_size(@width; @height){
//background-size:@width @height;
//-webkit-background-size:@width @height;
//}
//
////通用背景图片（按照top，left,no-repeat）
//.background_common_image(@url;@width;@height){
//.background_image(@url;no-repeat;0,0);
//.background_size(@width;@height);
//}
//
////flex box
//.flex_box_container() {
//display: box;
//display: -webkit-box;
//display: -moz-box;
//display: -ms-flexbox;
//display: -webkit-flex;
//display: flex;
//}
//.flex_box() {
//display: -ms-flexbox;
//display: -webkit-flex;
//display: flex;
//display: box;
//display: -webkit-box;
//display: -moz-box;
//}
//
//.box_flex(@num){
//-webkit-box-flex: @num;
//-moz-box-flex: @num;
//-webkit-flex: @num;
//-ms-flex: @num;
//flex: @num;
//}
//
////默认背景图片
//.default_bg(@size) {
//background: #fff url("@{pic_url_common}default130.png") no-repeat 50% 50%;
//background-size: @size @size;
//-webkit-background-size: @size @size;
//}
//
////大小尺寸
//.size(@width; @height) {
//width: @width;
//height: @height;
//}
//
////box_sizing
//.box_sizing() {
//-webkit-box-sizing: border-box;
//box-sizing: border-box;
//}
//.box_align(@val:start){
//box-align: @val;
//-mozbox-align: @val;
//-o-align: @val;
//-webkit-box-align: @val;
//}
//.box_pack(@val:start){
//box-pack: @val;
//-moz-box-pack: @val;
//-o-box-pack: @val;
//-webkit-box-pack: @val;
//}
//
//文字超出显示...
.ellipsis() {
text-overflow: ellipsis;
  white-space: nowrap;
     overflow: hidden;
}

//多行文字超出显示...
.multi_ellipsis(@line) {
-webkit-box-orient: vertical;
display: -webkit-box;
-webkit-line-clamp: @line;
overflow:hidden;
}

////隐藏文字
//.hide_text() {
//font: 0/0 arial;
//color: transparent;
//text-shadow: none;
//background-color: transparent;
//border: 0;
//}
//
////border小三角
//.border_triangle(@width; @color1; @color2; @top; @right; @bottom; @left) {
//width: @width;
//border-color: @color1 @color2;
//border-style: solid;
//border-width: @top @right @bottom @left;
//}
//
////border箭头
//.border_arrows(@wafter; @wbefore; @tafter; @tbefore){
//  display:inline-block;
//  width: 0;
//  height: 0;
//  position: relative;
//  &:after,&:before{
//      border: solid transparent;
//      content: ' ';
//      height: 0;
//      left: 100%;
//      position: absolute;
//      width: 0;
//  }
//  &:after{
//      border-width: @wafter;
//      border-left-color: #c8c8c8;
//      top: @tafter;
//  }
//  &:before{
//      border-width: @wbefore;
//      border-left-color: #fff;
//      top: @tbefore;
//  }
//}
//
////颜色
//.font(@color) {
//color: @color;
//}
//
////:before :after图标
//.before_icon(@width; @height){
//content: "";
//display: block;
//overflow: hidden;
//position:relative;
//.size(@width; @height);
//}
//
//.after_icon(@width; @height){
//content: "";
//display: block;
//overflow: hidden;
//position:relative;
//.size(@width; @height);
//}
//
////半像素border 图片实现
//.border_half() {
//  border-width: 0px;
//  border: 1px solid #c8c8c8;
//  -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
//}
//
//.border_top_half() {
//  border-width: 0;
//  border-top: 1px solid #c8c8c8;
//  -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
//}
//
//.border_bottom_half() {
//  border-width: 0;
//  border-bottom: 1px solid #c8c8c8;
//  -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
//}
//
//.border_left_half() {
//  border-width: 0;
//  border-left: 1px solid #c8c8c8;
//  -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
//}
//
//.border_right_half() {
//  border-width: 0;
//  border-right: 1px solid #c8c8c8;
//  -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
//}
//
//.border_vertical_half() {
//  border-width: 0;
//  border-top: 1px solid #c8c8c8;
//  border-bottom: 1px solid #c8c8c8;
//  -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
//}
//
//.border_horizontal_half() {
//  border-width: 0;
//  border-left: 1px solid #c8c8c8;
//  border-right: 1px solid #c8c8c8;
//  -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
//}
//
//.border_left_bottom_half() {
//  border-width: 0;
//  border-left: 1px solid #c8c8c8;
//  border-bottom: 1px solid #c8c8c8;
//  -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
//}
//
//.border_right_bottom_half() {
//  border-width: 0;
//  border-right: 1px solid #c8c8c8;
//  border-bottom: 1px solid #c8c8c8;
//  -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
//}
//
//.border_left_top_half() {
//  border-width: 0;
//  border-top: 1px solid #c8c8c8;
//  border-left: 1px solid #c8c8c8;
//  -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
//}
//
//.border_right_top_half() {
//  border-width: 0;
//  border-top: 1px solid #c8c8c8;
//  border-right: 1px solid #c8c8c8;
//  -webkit-border-image: url("@{pic_url_common}icon_border_half.png") 2 stretch;
//}
//
////半像素border 背景渐变实现
//.border_bottom(@color) {
//border: none;
//// background-image: -moz-linear-gradient(top, transparent, transparent 50%, @color 50%); // FF 3.6+
//background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(@color)); // Safari 4+, Chrome 2+
//background-image: -webkit-linear-gradient(top, transparent, transparent 50%, @color 50%); // Safari 5.1+, Chrome 10+
//// background-image: -o-linear-gradient(top, transparent, transparent 50%, @color 50%); // Opera 11.10
//background-image: linear-gradient(to bottom, transparent, transparent 50%, @color 50%); // Standard, IE10
//background-size: 100% 1px;
//background-repeat: no-repeat;
//background-position: bottom;
//}
//
//.border_top(@color) {
//border: none;
//// background-image: -moz-linear-gradient(top, @color, @color 50%, transparent 50%); // FF 3.6+
//background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(@color)); // Safari 4+, Chrome 2+
//background-image: -webkit-linear-gradient(top, @color, @color 50%, transparent 50%); // Safari 5.1+, Chrome 10+
//// background-image: -o-linear-gradient(top, @color, @color 50%, transparent 50%); // Opera 11.10
//background-image: linear-gradient(to bottom, @color, @color 50%, transparent 50%); // Standard, IE10
//background-size: 100% 1px;
//background-repeat: no-repeat;
//background-position: top;
//}
//
////圆角
//.border-radius(@radius) {
//border-radius: @radius;
//-webkit-border-radius: @radius;
//}
//
//.border_top_radius(@radius) {
//border-top-right-radius: @radius;
//border-top-left-radius: @radius;
//-webkit-border-top-right-radius: @radius;
//-webkit-border-top-left-radius: @radius;
//}
//
//.border_right_radius(@radius) {
//border-bottom-right-radius: @radius;
//border-top-right-radius: @radius;
//-webkit-border-top-right-radius: @radius;
//-webkit-border-top-left-radius: @radius;
//}
//
//.border_bottom_radius(@radius) {
//border-bottom-right-radius: @radius;
//border-bottom-left-radius: @radius;
//-webkit-border-top-right-radius: @radius;
//-webkit-border-top-left-radius: @radius;
//}
//
//.border_left_radius(@radius) {
//-webkit-border-bottom-left-radius: @radius;
//-webkit-border-top-left-radius: @radius;
//border-bottom-left-radius: @radius;
//border-top-left-radius: @radius;
//}
//
//.border-top-left-radius(@radius) {
//-webkit-border-top-left-radius: @radius;
//    // -moz-border-radius-topleft: @radius;
//        border-top-left-radius: @radius;
//}
//.border-top-right-radius(@radius) {
//-webkit-border-top-right-radius: @radius;
//    // -moz-border-radius-topright: @radius;
//        border-top-right-radius: @radius;
//}
//.border-bottom-right-radius(@radius) {
//-webkit-border-bottom-right-radius: @radius;
//    // -moz-border-radius-bottomright: @radius;
//        border-bottom-right-radius: @radius;
//}
//.border-bottom-left-radius(@radius) {
//-webkit-border-bottom-left-radius: @radius;
//    // -moz-border-radius-bottomleft: @radius;
//        border-bottom-left-radius: @radius;
//}
//
////box阴影
//.box_shadow(@x; @y; @r; @color) {
//-webkit-box-shadow: @x, @y, @r, @color;
//box-shadow: @x @y @r @color;
//}
//
////box阴影
//.text_shadow(@x; @y; @r; @color){
//text-shadow:@x @y @r @color;
//}
//
////animation
//.animation(@animation) {
//-webkit-animation: @animation;
//animation: @animation;
//}
//
////Transformations/2D
//.rotate(@degrees) {
//-webkit-transform: rotate(@degrees);
//transform: rotate(@degrees);
//}
//
//.scale(@ratioX; @ratioY) {
//-webkit-transform: scale(@ratioX, @ratioY);
//transform: scale(@ratioX, @ratioY);
//}
//
//.translate(@x; @y) {
//-webkit-transform: translate(@x, @y);
//transform: translate(@x, @y);
//}
//
//.skew(@x; @y) {
//-webkit-transform: skew(@x, @y);
//transform: skew(@x, @y);
//}
//
////Transformations/3D
//.translate3d(@x; @y; @z) {
//-webkit-transform: translate3d(@x, @y, @z);
//transform: translate3d(@x, @y, @z);
//}
//
////元素基点
//.transform_origin(@originX; @originY) {
//-webkit-transform-origin: @originX,@originY;
//transform-origin: @originX, @originY;
//}
//
////Transitions
//.transition(@property; @duration; @function; @delay) {
//-webkit-transition: @property, @duration, @function, @delay;
//transition: @property, @duration, @function, @delay;
//}
//
////线性渐变
//.linear_gradient(@direction; @color1; @color2){
//background-image:-webkit-linear-gradient(to @direction, @color1, @color2);
//background-image:linear-gradient(to @direction, @color1, @color2);
//}
//
//Opacity
.opacity(@opacity) {
    opacity: @opacity / 100;
    -webkit-opacity: @opacity / 100;
    filter: ~"alpha(opacity=@{opacity})";
    -wekkit-filter: ~"alpha(opacity=@{opacity})";
}
//
////图片自适应
//.maxwidth(){
//max-width: 100%;
//height: auto;
//}
//
////webkit-box弹性盒子模型
//.webkit-box(@orient:horizontal;@align:start;@pack:start;){
//  display:-webkit-box;
//  -webkit-box-orient:@orient;
//  -webkit-box-align:@align;
//  -webkit-box-pack:@pack;
//  box-sizing:border-box;
//}
////清除所有浮动
//.clear{clear: both;}
//// 图片url默认路径
//// -------------------------
//@pic_url_common: "../../../public/img/";
//@pic_url: "..//assets/images/";
//.ajax_loading{
//  width: 100%;
//  height: 100%;
//  position: fixed;
//  left: 0;
//  top: 0;
//  z-index: 100000;
//  overflow: hidden;
//  .info{
//      display: block;
//      content: "";
//      position: absolute;
//      left: 50%;
//      top: 50%;
//      margin: -15px 0 0 -30px;
//      background: url("@{pic_url}loading.gif") no-repeat;
//      background-size:80px 30px ;
//      width: 80px;
//      height:30px;
//      z-index: 3;
//  }
//  .mask{
//      background: #FFF;
//      width: 100%;
//      height: 100%;
//      top: 0;
//      position: absolute;
//      left: 0;
//      .opacity(90)
//  }
//  &.absolute{
//      position: absolute;
//      background: #FFF;
//      .mask{
//          top: 0;
//      }
//  }
//}
//
//.bgwhite{background: #FFF;}
//.btn_orange{
//  width: 100%;
//  height: 50px;
//  line-height: 50px;
//  text-align: center;
//  color: #FFF;
//  background: @orange;
//  font-size: 1.3rem;
//  font-family: inherit;
//  display: block;
//}
////购物车无数据
//.noCartData{
//  text-align: center;
//  line-height: 0;
//  height: 69px;
//  padding-top: 10px;
//  margin-top: -10px;
//  color: #999;
//  .icon2{
//      width: 56px;
//      height: 49px;
//      line-height: 0;
//      font-size: 0;
//      background: url("@{pic_url}/settleup-nogoods.png") 0 0 no-repeat;
//      position:relative;
//      top:16px;
//      display: inline-block;
//  }
//  .icon3{
//              width: 164px;
//              height: 155px;
//              line-height: 0;
//              font-size: 0;
//              background: url("@{pic_url}/newcar.png") 0 0 no-repeat;
//              position:relative;
//              top:16px;
//              display: inline-block;
//          }
//  .txt{
//      line-height: 50px;
//      display: inline-block;
//  }
//  .txt2{
//      line-height: 50px;
//      display: block;
//      font-size: 22px;color:#666;margin-top: 32px;
//  }
//
//}
////搜索列表无数据
//.nf-pro-content{
//  padding: 60px 0;
//  color: #999;
//  text-align: left;
//  font-size: 12px;
//  line-height: 180%;
//  width: 364px;
//  margin: 0 auto;
//  .result{
//      padding: 0 0 10px 0;
//      display: inline-block;
//      font-size: 28px;
//  }
//  p{
//      padding: 0 0 0 20px ;
//  }
//  .btn-default{
//      margin-left: 20px;
//      margin-top: 10px;
//      padding: 4px 13px 5px;
//      background: #f7f7f7;
//      border: 1px solid #DDD;
//      border-radius: 5px;
//      display: inline-block;
//      color: #666;
//      background-repeat: repeat-x;
//      background-image:-moz-linear-gradient(top,#f7f7f7,#f2f2f2);
//      background-image:-webkit-linear-gradient(top,#f7f7f7,#f2f2f2);
//      background-image:-o-linear-gradient(top,#f7f7f7,#f2f2f2);
//      background-image:linear-gradient(top,#f7f7f7,#f2f2f2);
//      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f7f7', endColorstr='#f2f2f2', GradientType=0);
//      &:hover{
//          color: @blue;
//      }
//  }
//}
//// 公用字体
//@font10:10px;
//@font11:11px;
//@font12:12px;
//@font13:13px;
//@font14:14px;
//@font15:15px;
//@font16:16px;
//@font17:17px;
//@font18:18px;
//@font19:19px;
//@font20:20px;
//@font21:21px;
//@font22:22px;
//@font23:23px;
//@font24:24px;
//@font25:25px;
//@font26:26px;
//@font27:27px;
//@font28:28px;
//@font29:2.9rem;
//@font30:3.0rem;
//@font36:3.6rem;
//@font48:4.8rem;
//@font50:5.0rem;
//@font58:5.8rem;
.font12{
  font-size: 12px;
}
.font14{
  font-size: 14px;
}
////公用color
//@c_fff:#fff;
//@c_000:#000;
//@c_333:#333;
//@c_444:#444;
//@c_666:#666;
//@c_999:#999;
//@c_a8b2c2:#a8b2c2;
//@c_e0edfc:#e0edfc;
//@c_e1e1e1:#e1e1e1;
//@c_b5b5b5:#b5b5b5;
//@c_a7b2c1:#a7b2c1;
//@c_4A586A:#4A586A;
//@c_4a586a:#4a586a;
//@c_c8c8c8:#c8c8c8;
//@c_d1d5de:#d1d5de;
//@blue:#5BB4FB;
//@red:#FF4C4C;
//@orange:#FF7F00;
//@c_d6d6d6:#d6d6d6;
//@gray:#96A2B5;
//@green:#79CD31;
//
//.orange{ color: @orange;}
//.blue{ color: @blue;}
//.bg5AA5F0{background:#5AA5F0 !important;}
//.red{color: @red;}
//.cFFF{color: #FFF;}
//.bgblue{ background: @blue !important;_background: @blue;}
.show{
    _display: block;
    display: block !important;
}
.margin100{
    margin: 0 100px;
}
.c_red{ color: #DC2728 !important; _color:#DC2728;}
.c_gray{ color: #666 !important; _color:#666;}
.c_orange{ color: #ff9435 !important; _color:#ff9435;}
.c_note{
    color: #828282 !important; _color:#828282;
}
.b_red{
    background: #dc2728;
}
.border0{
    border:0 !important;}
.w95{
    width: 95px !important;
    _width:95px;
}
.ml5{margin-left: 5px !important;}
.ml7{margin-left: 7px !important;}
.ml10{margin-left: 10px;}
.ml11{margin-left: 11px;}
.ml12{margin-left: 12px;}
.ml15{margin-left: 15px  !important;}
.ml20{margin-left: 20px  !important;}
.ml25{margin-left: 25px  !important;}
.ml30{margin-left: 30px  !important;}
.ml35{margin-left: 35px  !important;}
.ml40{margin-left: 40px  !important;}
.ml45{margin-left: 45px  !important;}
.ml50{margin-left: 50px  !important;}
.ml55{margin-left: 55px  !important;}
.ml60{margin-left: 60px  !important;}
.ml70{margin-left: 70px  !important;}
.ml185{margin-left: 185px  !important;}
.ml205{margin-left: 205px  !important;}


.mr5{margin-right: 5px  !important; _margin-right:5px;}
.mr11{margin-right: 11px  !important; _margin-right:11px;}
.mr15{margin-right: 15px  !important; _margin-right:15px;}
.mr20{margin-right: 20px  !important; _margin-right:20px;}
.mr30{margin-right: 30px  !important; _margin-right:30px;}
.mr50{margin-right: 50px  !important; _margin-right:50px;}
.mr60{margin-right: 60px  !important; _margin-right:60px;}
.mr70{margin-right: 70px  !important; _margin-right:70px}


.mt0{margin-top: 0  !important;}
.mt1{margin-top: 1px  !important;}
.mt2{margin-top: 2px  !important;}
.mt3{margin-top: 3px  !important;}
.mt5{margin-top: 5px  !important;}
.mt10{margin-top: 10px  !important;}
.mt12{margin-top: 12px  !important;}
.mt15{margin-top: 15px  !important;}
.mt20{margin-top: 20px  !important;}
.mt25{margin-top: 25px  !important;}
.mt30{margin-top: 30px  !important;}
.mt35{margin-top: 35px  !important;}
.mt40{margin-top: 40px  !important;}
.mt45{margin-top: 45px  !important;}
.mt50{margin-top: 50px  !important;}
.mt55{margin-top: 55px  !important;}
.mt60{margin-top: 60px  !important;}
.mt65{margin-top: 65px  !important;}
.mt70{margin-top: 70px  !important;}
.mt75{margin-top: 75px  !important;}
.mt78{margin-top: 78px  !important;}
.mt90{margin-top: 90px  !important;}

.mb5{margin-bottom: 5px  !important;}
.mb10{margin-bottom: 10px  !important;}
.mb15{margin-bottom: 15px  !important;}
.mb20{margin-bottom: 20px  !important;}
.mb30{margin-bottom: 30px  !important;}
.mb35{margin-bottom: 35px  !important;}
.mb40{margin-bottom: 40px  !important;}
.mb45{margin-bottom: 45px  !important;}
.mb50{margin-bottom: 50px  !important;}
.mb60{margin-bottom: 60px  !important;}
.mb100{margin-bottom: 100px  !important;}

.mr0{
    margin-right: 0 !important ;
}
.pt10{padding-top: 10px  !important;}
.pt15{padding-top: 15px  !important;}
.pt20{padding-top: 20px  !important;}
.pt30{padding-top: 30px  !important;}
.pt50{padding-top: 50px  !important;}
.pt55{padding-top: 55px  !important;}



.pb5{padding-bottom: 5px  !important;}
.pb10{padding-bottom: 10px  !important;}
.pb15{padding-bottom: 15px  !important;}
.pb20{padding-bottom: 20px  !important;}
.pb25{padding-bottom: 25px  !important;}
.pb30{padding-bottom: 30px  !important;}
.pb40{padding-bottom: 40px  !important;}
.pb55{padding-bottom: 55px  !important;}
.pb60{padding-bottom: 60px  !important;}


.pl5{padding-left: 5px  !important;}
.pl10{padding-left: 10px  !important;}
.pl15{padding-left: 15px  !important;}
.pl20{padding-left: 20px  !important;}
.pl25{padding-left: 25px  !important;}
.pl30{padding-left: 30px  !important;}
.pl34{padding-left: 34px  !important;}
.pl40{padding-left: 40px  !important;}
.pl45{padding-left: 45px  !important;}
.pl55{padding-left: 55px  !important;}
.pl60{padding-left: 60px  !important;}



.pr0{padding-right: 0px  !important;}
.pr5{padding-right: 5px  !important;}
.pr10{padding-right: 10px  !important;}
.pr15{padding-right: 15px  !important;}
.pr20{padding-right: 20px  !important;}
.pr25{padding-right: 25px  !important;}
.pr30{padding-right: 30px  !important;}
.pr34{padding-right: 34px  !important;}
.pr40{padding-right: 40px  !important;}
.pr55{padding-right: 55px  !important;}
.pr60{padding-right: 60px  !important;}

.h62{
    height: 62px;
}
.prompt{
    .c_note;
    font-size: 14px;
}
.fixed{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
}
.absolute{
    position: absolute;
    left: 0;
    top: 0;
}
.underline{
    text-decoration: underline;
}
.t12{
    top: 12px !important;
    _top:12px;
}
.tl{
    text-align: left !important; _text-align:left;
}
.tr{text-align: right}
.tc{text-align: center !important; _text-align:center;}
.l40{
  left: 40px !important;_left:40px;
}
.l51{
    left: 51px !important;_left:51px;
}
.l100{
  left: 100px !important;_left:100px;
}
.l265{
    left: 265px !important; _left:265px;
}
.l300{
  left: 300px !important;_left:300px;
}
.l375{
    left: 375px !important; _left:375px;
}
.l400{
  left: 400px !important;_left:400px;
}
.l670{
  left: 670px !important;_left:670px;
}
.l580{
  left: 580px !important;_left:580px;
}
.blue{
    background: #8CC9F6;
}
.green{
    background: #00CB0C;
}
.orange{
    background: #FF6000;
}

.c_title{
    color: #3B3A40 !important;
    _color: #3B3A40 !important;
}
.c_note{
    color: #999 !important;
    _color: #999 !important;
}
.inline{
    .inline-block;
    vertical-align: top;
}
.noData{
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 200%;
    padding-top: 100px;
    color: #999;
    a{
        color: #dc2728;
    }
}