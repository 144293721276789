#footer{
    background: #9B9B9B;
    width: 100%;
    height: 54px;
    line-height: 54px;
    text-align: center;
    font-size: 12px;
    color: #FFF;
    position: fixed;
    bottom:0;
    left:0;
    z-index: 100;
    &.notFixed{
        position: inherit;
    }
}