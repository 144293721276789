.pace {
  width: 140px;
  height: 300px;
  position: fixed;
  top:50%;
  right: 50%;
  margin:  -150px 0 0 0px;
  z-index: 2000;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 2s linear 0s;
  -moz-transition: all 2s linear 0s;
  transition: all 2s linear 0s;
}

.pace.pace-active {
  -webkit-transform: scale(.4);
  -moz-transform: scale(.4);
  -ms-transform: scale(.4);
  -o-transform: scale(.4);
  transform: scale(.4);
  opacity: 1;
}

.pace .pace-activity {
  width: 140px;
  height: 140px;
  line-height: 140px;
  border-radius: 140px !important;
  background: #29d;
  position: absolute;
  top: 0;
  z-index: 1911;
  -webkit-animation: pace-bounce 1s infinite;
  -moz-animation: pace-bounce 1s infinite;
  -o-animation: pace-bounce 1s infinite;
  -ms-animation: pace-bounce 1s infinite;
  animation: pace-bounce 1s infinite;

}

.pace .pace-progress {
  position: absolute;
  display: block;
  left: 50%;
  bottom: 0;
  z-index: 1910;
  margin-left: -30px;
  width: 60px;
  height: 75px;
  background: rgba(20, 20, 20, .1);
  box-shadow: 0 0 20px 35px rgba(20, 20, 20, .1);
  border-radius: 30px / 40px !important;
  -webkit-transform: scaleY(.5) !important;
  -moz-transform: scaleY(.5) !important;
  -ms-transform: scaleY(.5) !important;
  -o-transform: scaleY(.5) !important;
  transform: scaleY(.5) !important;
  -webkit-animation: pace-compress .5s infinite alternate;
  -moz-animation: pace-compress .5s infinite alternate;
  -o-animation: pace-compress .5s infinite alternate;
  -ms-animation: pace-compress .5s infinite alternate;
  animation: pace-compress .5s infinite alternate;
}

@-webkit-keyframes pace-bounce {
  0% {
    top: 0;
    -webkit-animation-timing-function: ease-in;
  }
  40% {}
  50% {
    top: 140px;
    height: 140px;
    -webkit-animation-timing-function: ease-out;
  }
  55% {
    top: 160px;
    height: 120px;
    //border-radius: 70px / 60px;
    -webkit-animation-timing-function: ease-in;
  }
  65% {
    top: 120px;
    height: 140px;
    //border-radius: 70px;
    -webkit-animation-timing-function: ease-out;
  }
  95% {
    top: 0;
    -webkit-animation-timing-function: ease-in;
  }
  100% {
    top: 0;
    -webkit-animation-timing-function: ease-in;
  }
}

@-moz-keyframes pace-bounce {
  0% {
    top: 0;
    -moz-animation-timing-function: ease-in;
  }
  40% {}
  50% {
    top: 140px;
    height: 140px;
    -moz-animation-timing-function: ease-out;
  }
  55% {
    top: 160px;
    height: 120px;
    //border-radius: 70px / 60px;
    -moz-animation-timing-function: ease-in;
  }
  65% {
    top: 120px;
    height: 140px;
    //border-radius: 70px;
    -moz-animation-timing-function: ease-out;}
  95% {
    top: 0;
    -moz-animation-timing-function: ease-in;
  }
  100% {top: 0;
    -moz-animation-timing-function: ease-in;
  }
}

@keyframes pace-bounce {
  0% {
    top: 0;
    animation-timing-function: ease-in;
  }
  50% {
    top: 140px;
    height: 140px;
    animation-timing-function: ease-out;
  }
  55% {
    top: 160px;
    height: 120px;
    //border-radius: 70px / 60px;
    animation-timing-function: ease-in;
  }
  65% {
    top: 120px;
    height: 140px;
    //border-radius: 70px;
    animation-timing-function: ease-out;
  }
  95% {
    top: 0;
    animation-timing-function: ease-in;
  }
  100% {
    top: 0;
    animation-timing-function: ease-in;
  }
}

@-webkit-keyframes pace-compress {
  0% {
    bottom: 0;
    margin-left: -30px;
    width: 60px;
    height: 75px;
    background: rgba(20, 20, 20, .1);
    box-shadow: 0 0 20px 35px rgba(20, 20, 20, .1);
    //border-radius: 30px / 40px;
    -webkit-animation-timing-function: ease-in;
  }
  100% {
    bottom: 30px;
    margin-left: -10px;
    width: 20px;
    height: 5px;
    background: rgba(20, 20, 20, .3);
    box-shadow: 0 0 20px 35px rgba(20, 20, 20, .3);
    //border-radius: 20px / 20px;
    -webkit-animation-timing-function: ease-out;
  }
}

@-moz-keyframes pace-compress {
  0% {
    bottom: 0;
    margin-left: -30px;
    width: 60px;
    height: 75px;
    background: rgba(20, 20, 20, .1);
    box-shadow: 0 0 20px 35px rgba(20, 20, 20, .1);
    //border-radius: 30px / 40px;
    -moz-animation-timing-function: ease-in;
  }
  100% {
    bottom: 30px;
    margin-left: -10px;
    width: 20px;
    height: 5px;
    background: rgba(20, 20, 20, .3);
    box-shadow: 0 0 20px 35px rgba(20, 20, 20, .3);
    //border-radius: 20px / 20px;
    -moz-animation-timing-function: ease-out;
  }
}

@keyframes pace-compress {
  0% {
    bottom: 0;
    margin-left: -30px;
    width: 60px;
    height: 75px;
    background: rgba(20, 20, 20, .1);
    box-shadow: 0 0 20px 35px rgba(20, 20, 20, .1);
    //border-radius: 30px / 40px;
    animation-timing-function: ease-in;
  }
  100% {
    bottom: 30px;
    margin-left: -10px;
    width: 20px;
    height: 5px;
    background: rgba(20, 20, 20, .3);
    box-shadow: 0 0 20px 35px rgba(20, 20, 20, .3);
    //border-radius: 20px / 20px;
    animation-timing-function: ease-out;
  }
}

#loading_bar{
  font-weight: 100;
  font-size: 50px;
  color: #D8D8D8;
  background: #FFF;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1011111;
  /*color: rgba(0, 0, 0, 0.19999999999999996);*/
  text-align: center;
  font-family: "Helvetica Neue", sans-serif;

}

#ajax_loading_t{
  width: 100%;
  height: 100%;
  position: fixed;
  left:0;
  top:0;
  background: #F6F6F6;
  z-index: 99999;
}
.spinner {
  width: 90px;
  height: 90px;
  position: absolute;
  left: 50%;
  top:50%;
  margin:-55px 0 0 -45px;
  text-align: center;

  -webkit-animation: rotate 2.0s infinite linear;
  animation: rotate 2.0s infinite linear;
}

.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #DC2728;
  border-radius: 100%;

  -webkit-animation: bounce 2.0s infinite ease-in-out;
  animation: bounce 2.0s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0px;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

@-webkit-keyframes bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
}
